<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick1">
            <el-tab-pane label="新订单" name="first">
                <div class="left">
                    <div class="left_title">
                        <span>共：{{ total2 }}条</span>
                        <el-button type="danger" @click="receive1()">分配</el-button>
                    </div>
                    <div class="left_cont">
                        <div>
                            <el-table
                                    ref="multipleTable"
                                    :header-cell-style="headeRowClass"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                    @selection-change="handleSelectionChange2"
                                    :data="tableData2"
                            >
                                <el-table-column type="selection" align="center">
                                </el-table-column>

                                <el-table-column
                                        prop="title"
                                        label="文章标题"
                                        sortable
                                        :show-overflow-tooltip="true"
                                >
                                </el-table-column>
                                <el-table-column prop="editor" label="作者" sortable>
                                </el-table-column>
                                <el-table-column prop="staffName" label="订单所属" sortable>
                                </el-table-column>
                                <el-table-column
                                        label="日期"
                                        show-overflow-tooltip
                                        sortable
                                        align="center"
                                >
                                    <template slot-scope="scope">{{
                                        scope.row.operation_time
                                        }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="order_No"
                                        label="订单号"
                                        sortable
                                        align="center"
                                ></el-table-column>
                                <el-table-column prop="attribute" label="订单状态" sortable>
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.attribute == 0">普通</span>
                                        <span v-if="scope.row.attribute >= 1">加急</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>

                    <el-dialog
                            title="分配给"
                            :visible.sync="dialog1"
                            width="503px"
                            :close-on-click-modal="false"
                    >
                        <div>
                            <el-form ref="form" :model="form">
                                <el-form-item>
                                    <el-select
                                            filterable
                                            clearable
                                            v-model="form1.region"
                                            placeholder="请选择员工"
                                            style="width: 80%"
                                    >
                                        <el-option
                                                v-for="item in options"
                                                :key="item.id"
                                                :label="item.realname"
                                                :value="item.id"
                                        >
                                            <span style="float: left">{{ item.realname }}</span>
                                            <span style="float: right; color: red; font-size: 13px"
                                            >处理中：{{ item.geshu }}件</span
                                            >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div slot="footer" class="dialog-footer">
                            <el-button @click="dialog1 = false">取 消</el-button>
                            <el-button type="danger" @click="distribute()" :loading="loading"
                            >确 定
                            </el-button
                            >
                        </div>
                    </el-dialog>

                    <div class="orderPage">
                        <el-pagination
                                class="fy"
                                small
                                @size-change="handleSizeChange2"
                                :page-size="pagesize2"
                                :page-sizes="[10, 20, 30]"
                                layout="total, sizes, prev, pager, next, jumper"
                                @current-change="current_change2"
                                :total="total2"
                                background
                        >
                        </el-pagination>
                    </div>
                </div>
            </el-tab-pane>


            <el-tab-pane label="订单列表" name="second">
                <div class="yue">

                    <span>成交金额</span>
                    <br><br><br>
                    <div class="yue-list">
                        <h2 style="width: 100px; height: 40px; line-height: 40px">
                            {{ allAmount }}
                        </h2>
                        <!--        <div style="width: 100px; margin-right: 10px">-->
                        <!--          <el-button-->
                        <!--                  type="danger"-->
                        <!--                  @click="TixianTap"-->
                        <!--                  style="width: 100px; background: red; border: red"-->
                        <!--          >提现</el-button-->
                        <!--          >-->
                        <!--        </div>-->
                    </div>
                    <br>
                    <br>
                </div>


                <div class="status_title">全订单状态</div>
                <div class="left_title">
                    <span>共计：{{ total }}条</span>
                </div>
                <div class="tab1" v-show="status">
                    <div class="status_form">
                        <el-form
                                size="mini"
                                :inline="true"
                                :model="formInline"
                                :label-position="labelPosition"
                                label-width="80px"
                                class="demo-form-inline"
                        >
                            <el-form-item label="作者">
                                <el-input v-model="formInline.editor" placeholder="作者"></el-input>
                            </el-form-item>
                            <el-form-item label="文章题目">
                                <el-input
                                        v-model="formInline.title"
                                        placeholder="文章题目"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="处理人">
                                <el-input
                                        v-model="formInline.realname"
                                        placeholder="处理人"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="属性">
                                <el-col :span="22">
                                    <el-select
                                            v-model="formInline.attribute"
                                            clearable
                                            placeholder="属性"
                                    >
                                        <el-option label="普通" value="0"></el-option>
                                        <el-option label="加急" value="1"></el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                            <el-form-item label="订单状态">
                                <el-col :span="22">
                                    <el-select
                                            v-model="formInline.qikan_state"
                                            clearable
                                            placeholder="订单状态"
                                    >
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="待处理" value="55"></el-option>
                                        <el-option label="平台待审" value="0"></el-option>
                                        <el-option label="平台已审" value="6"></el-option>
                                        <el-option label="期刊待审" value="2"></el-option>
                                        <el-option label="期刊已审" value="5"></el-option>
                                        <el-option label="平台驳回" value="71"></el-option>
                                        <el-option label="期刊驳回" value="72"></el-option>
                                        <el-option label="作者已确认" value="47"></el-option>
                                        <el-option label="出刊邮寄" value="4"></el-option>
                                        <el-option label="异常退款" value="44"></el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                            <!--          <el-form-item label="投稿时间">-->
                            <!--            <el-date-picker-->
                            <!--              type="date"-->
                            <!--              value-format="yyyy-MM-dd"-->
                            <!--              placeholder="选择日期"-->
                            <!--              v-model="formInline.starttime"-->
                            <!--              style="width: 130px"-->
                            <!--            ></el-date-picker>-->
                            <!--            <span class="to">至</span>-->
                            <!--            <el-date-picker-->
                            <!--              type="date"-->
                            <!--              value-format="yyyy-MM-dd"-->
                            <!--              placeholder="选择日期"-->
                            <!--              v-model="formInline.endtime"-->
                            <!--              style="width: 130px"-->
                            <!--            ></el-date-picker>-->
                            <!--          </el-form-item>-->

                            <el-form-item label="是否结算">
                                <el-col :span="23">
                                    <el-select
                                            v-model="formInline.isAettlement"
                                            clearable
                                            placeholder="订单状态"
                                    >
                                        <el-option label="全部" value="3"></el-option>
                                        <el-option label="否" value="0"></el-option>
                                        <el-option label="是" value="1"></el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="danger" @click="onSubmit">查询</el-button>

                            </el-form-item>


                        </el-form>
                    </div>
                    <div class="container_table">
                        <el-table
                                :header-cell-style="headeRowClass"
                                :data="tableData"
                                stripe
                                style="width: 100%"
                                :default-sort="{ prop: 'date', order: 'descending' }"
                        >
                            <el-table-column prop="title" label="文章标题"  width="120" sortable>
                            </el-table-column>
                            <el-table-column prop="editor" label="作者"  width="120" sortable>
                            </el-table-column>
                            <el-table-column label="投稿时间" prop="operation_time"  width="120" sortable>
                            </el-table-column>

                            <el-table-column prop="realname" label="当前处理人" width="120" sortable>
                            </el-table-column>
                            <el-table-column prop="state" label="订单状态"  width="120" sortable>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.state == 55">待处理 </span>
                                    <span v-if="scope.row.state == 0">平台待审 </span>
                                    <span v-if="scope.row.state == 6">平台已审</span>
                                    <span v-if="scope.row.state == 2">期刊待审 </span>
                                    <span v-if="scope.row.state == 5">期刊已审</span>
                                    <span v-if="scope.row.state == 71">平台驳回 </span>
                                    <span v-if="scope.row.state == 72">期刊驳回</span>
                                    <span v-if="scope.row.state == 47">作者已确认 </span>
                                    <span v-if="scope.row.state == 4">出刊邮寄</span>
                                    <span v-if="scope.row.state == 44">异常退款</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="attribute" label="属性" sortable>
                                <!--在职是0，离职是1-->
                                <template slot-scope="scope">
                                    <span v-if="scope.row.attribute == 0">普通 </span>
                                    <span v-if="scope.row.attribute == 1">加急</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="attribute" label="是否结算"  width="120" sortable>
                                <!--在职是0，离职是1-->
                                <template slot-scope="scope">
                                    <span v-if="scope.row.isAettlement == 0">未结算 </span>
                                    <span v-if="scope.row.isAettlement == 1">已结算</span>
                                    <span v-if="scope.row.isAettlement == 2">已处理</span>
                                </template>
                            </el-table-column>

                            <el-table-column prop="amount" label="成交金额"  width="120" sortable>
                            </el-table-column>

                            <el-table-column prop="refundReason" label="异常原因"  width="120" sortable>
                            </el-table-column>

                            <el-table-column fixed="right" label="操作" width="250">
                                <template slot-scope="scope">
                                    <el-button
                                            @click="handleClick(scope.row)"
                                            type="text"
                                            size="small"
                                    >查看
                                    </el-button
                                    >
                                    <el-button @click="receive(scope.row)" type="text" size="small"
                                    >更换处理人
                                    </el-button
                                    >
                                    <el-button @click="jiesuan(scope.row)" type="text" size="small"
                                    >结算
                                    </el-button
                                    >
                                    <el-button @click="yichang(scope.row)" type="text" size="small"
                                    >异常处理
                                    </el-button
                                    >
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="orderPage">
                            <el-pagination
                                    class="fy"
                                    small
                                    @size-change="handleSizeChange"
                                    :page-size="pagesize"
                                    :page-sizes="[10, 20, 30]"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    @current-change="current_change"
                                    :total="total"
                                    background
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
                <!--  详情-->
                <div class="tab2" v-show="!status">
                    <div class="articleTitle">{{ article.title }}</div>
                    <div class="xs">
        <span class="author">作者：{{ article.editor }}</span
        ><span>期刊：{{ article.name }}</span>
                    </div>
                    <p class="bt">附件</p>
                    <div class="file" @click="downloadAttach">
                        <i class="el-icon-paperclip"></i><a>点击下载附件</a>
                    </div>
                    <p class="bt">摘要</p>
                    <div class="articletext">
                        {{ article.summary }}
                    </div>
                    <p class="bt">关键词</p>
                    <div class="articletext">
                        {{ article.keyword }}
                    </div>
                    <p class="bt">备注</p>
                    <div class="articletext">
                        {{ article.remarks }}
                    </div>
                    <el-button type="danger" @click="back()" class="btn">返回</el-button>
                </div>

                <!--弹窗-->
                <el-dialog
                        title="更换为"
                        :visible.sync="dialog"
                        width="503px"
                        :close-on-click-modal="false"
                >
                    <div>
                        <el-form ref="form" :model="form">
                            <el-form-item>
                                <el-select
                                        filterable
                                        v-model="form.region"
                                        placeholder="请选择员工"
                                        style="width: 80%"
                                >
                                    <el-option
                                            v-for="item in options"
                                            :key="item.id"
                                            :label="item.realname"
                                            :value="item.id"
                                    >
                                        <span style="float: left">{{ item.realname }}</span>
                                        <span style="float: right; color: red; font-size: 13px"
                                        >处理中：{{ item.geshu }}件</span
                                        >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialog = false">取 消</el-button>
                        <el-button type="danger" @click="staffchange()" :loading="loading"
                        >确 定
                        </el-button
                        >
                    </div>
                </el-dialog>
            </el-tab-pane>
        </el-tabs>



        <el-dialog
                title="异常备注"
                :visible.sync="dialog4"
                width="503px"
                :close-on-click-modal="false"
                center
        >
            <template slot-scope="scope">
                <el-input
                        v-model="form4.refundReason"
                        placeholder="请输入异常备注"
                ></el-input>
                <br/><br/>

                <el-button class="left" type="danger" @click="confirm1(scope.row)" :loading="loading">确 定
                </el-button>
            </template>

        </el-dialog>
    </div>


</template>

<script>
    import {searchOrder, fwsorderalls, jsamount,jiesuan,yichang} from '@/API/orderstatus'
    import {changeStaff} from '@/API/orderstatus'
    import {staffList} from '@/API/toDoOrder'
    import {checksteps, checktrace, checkarticle} from "../../../API/QKD/detail";
    import steps from '../../DePenDent/components/steps'
    import trace from '../../DePenDent/components/trace'
    import articlecont from "../../DePenDent/components/articlecont";
    import {Balanced} from "../../../API/Fuwu";
    import {todoOrder} from '@/API/toDoOrder';
    import {orderDistribute} from '@/API/toDoOrder';
    import {courierInfo} from "../../../API/QKD/balance";

    export default {


        data() {
            return {
                yue: '', // 余额
                allAmount:'',
                id: "",//订单的id，点击更改员工时赋值
                labelPosition: 'right',//标签label对齐方式
                status: true,//控制显示详情还是显示列表
                loading: false,
                id: "",//供应商id
                form: {//选择员工表单数据
                    name: '',
                    region: ''
                },
                myId:"",
                form4: {//异常信息
                    refundReason: ''

                },
                form1: {//选择员工表单数据
                    name: '',
                    region: ''
                },
                orderid2: [],
                dialog: false, //默认不显示弹窗
                dialog1: false, //默认不显示弹窗
                dialog4: false, //默认不显示弹窗
                //表单基本数据
                formInline: {
                    id: "",
                    page: "",
                    limit: "",
                    starttime: "",
                    endtime: "",
                    editor: "",
                    title: "",
                    attribute: "",
                    realname: "",
                    qikan_name: "",
                    qikan_state: "",
                    isAettlement: ""

                },
                activeName: 'first',
                value3: '',
                total: 0, //默认数据总数
                total2: 0, //默认数据总数
                pagesize: 10, //每页的数据条数
                pagesize2: 10,//每页的数据条数
                currentPage2: 1, //默认开始页面

                currentPage: 1, //默认开始页面
                tableData: [//订单列表数据
                ],
                tableData2: [//订单列表数据
                ],
                article: {//文章详情数据

                },

                options: [],
                multipleSelection2: [],
            }
        },
        // mounted () {
        //   this.onSubmit()
        // },
        methods: {
            // 下载附件
            downloadAttach() {

                let Path = this.article.content
                console.log(Path);
                downloadFile(Path)
            },
            handleClick1(tab, event) {
                console.log(tab, event);
            },
            //
            onSubmit() {
                let staff_id = null;
                this.formInline.id = localStorage.getItem('FuWuId');
                this.formInline.limit = this.pagesize
                this.formInline.page = this.currentPage
                console.log(this.formInline);
                // fwsorderalls(this.formInline.id, this.formInline.page,
                //     this.formInline.limit, this.formInline.starttime, this.formInline.endtime, this.formInline.editor,
                //     this.formInline.title, this.formInline.attribute, this.formInline.realname, this.formInline.qikan_name, this.formInline.qikan_state, staff_id, this.formInline.isAettlement).then(res => {
                //     if (res.data.code == 0) {
                //         this.tableData = res.data.data
                //         this.total = res.data.total
                //
                //     }
                // })

                jsamount(this.formInline.id, this.formInline.page,
                    this.formInline.limit, this.formInline.starttime, this.formInline.endtime, this.formInline.editor,
                    this.formInline.title, this.formInline.attribute, this.formInline.realname, this.formInline.qikan_name, this.formInline.qikan_state, staff_id, this.formInline.isAettlement).then(res => {
                    if (res.data.code == 0) {
                        this.tableData = res.data.data
                        this.total = res.data.total
                        this.allAmount=res.data.data.allAmount


                        fwsorderalls(this.formInline.id, this.formInline.page,
                            this.formInline.limit, this.formInline.starttime, this.formInline.endtime, this.formInline.editor,
                            this.formInline.title, this.formInline.attribute, this.formInline.realname, this.formInline.qikan_name, this.formInline.qikan_state, staff_id, this.formInline.isAettlement).then(res => {
                            if (res.data.code == 0) {
                                this.tableData = res.data.data
                                this.total = res.data.total

                            }
                        })



                    }
                })

            },

            jsamount() {
                let staff_id = null;
                this.formInline.id = localStorage.getItem('FuWuId');
                this.formInline.limit = this.pagesize
                this.formInline.page = this.currentPage
                console.log(this.formInline);
                jsamount(this.formInline.id, this.formInline.page,
                    this.formInline.limit, this.formInline.starttime, this.formInline.endtime, this.formInline.editor,
                    this.formInline.title, this.formInline.attribute, this.formInline.realname, this.formInline.qikan_name, this.formInline.qikan_state, staff_id, this.formInline.isAettlement).then(res => {
                    if (res.data.code == 0) {
                        this.tableData = res.data.data
                        this.total = res.data.total
                        this.allAmount=res.data.data.allAmount
                    }
                })
            },

            jiesuan(tab){
                let id = tab.works_id;
                let state=tab.state;
                let isAettlement=tab.isAettlement;
             //   debugger;
                if(isAettlement==1){
                    this.$message.success("该单已结算");
                }else {
                    if(state!=47){
                        this.$message.success("作者未确认");
                    }else if(state==47){

                        jiesuan(id).then(res => {

                            if (res.data.code == 0) {
                                this.$message.success(res.data.msg);
                                this.jsamount();
                                this.onSubmit();
                            }

                        })
                    }
                }

            },

            //异常弹框
            yichang(row) {
                if(row.state!=44){
                    return  this.$message.success("该数据无异常情况！")
                }
                //显示弹框
                this.dialog4 = true;
                this.myId = row.works_id;
            },
            confirm1(row) {
                alert(this.myId);
                yichang(this.myId, this.form4.refundReason).then(res => {
                    //  console.log(res)
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg)
                        this.dialog = false;
                        this.jsamount();
                        this.onSubmit();
                    } else {
                        this.$message.error(res.data.msg)
                        this.dialog4 = false;
                    }
                })
            },

            balanced() {
                let id = localStorage.getItem('FuWuId')

                Balanced(id).then(res => {
                    this.yue = res.data.data
                })
            },
            //点击查看显示详情页，隐藏列表页
            handleClick(tab) {
                let wid = tab.works_id;
                this.$router.push({path: "/Services/orderStatusDetail", query: {id: 4, wid: wid}})

            },
            //点击更换处理人
            change(tab, event) {
             //   alert(tab.state);

                console.log(tab, event);
                this.status = false

            },
            staffchange() {
                this.dialog = false
                changeStaff(this.id, this.form.region).then(res => {
                    if (res.data.code == 0) {
                        this.$message({
                            message: '更改成功',
                            type: 'success'
                        });
                        this.onSubmit()
                    }


                })

            },
            // 更改表头样式
            headeRowClass({
                              row,
                              column,
                              rowIndex,
                              columnIndex
                          }) {
                if (rowIndex === 0) {
                    return 'background-color: #F2F2F2;color: #666666;font-weight: 700;'
                }
            },
            // 分页
            current_change: function (currentPage) {
                this.currentPage = currentPage;
                this.onSubmit()
            }, handleSizeChange(val) {
                console.log(val);
                this.pagesize = val;
                this.onSubmit()
            },

            //代办
            handleSizeChange2(val) {
                console.log(val);
                this.pagesize2 = val;
                this.todoOrder()
            },
            // 二次订单分页
            current_change2: function (currentPage) {
                this.currentPage2 = currentPage;
                this.todoOrder()
            },
            //弹窗
            receive: function (row) {
                if(row.state==47){
                    return this.$message.success("作者已完成确认，不允许更换！")
                }else if(row.state==4){
                    return this.$message.success("订单已结算，不允许更换！")
                }else if(row.state==44){
                    return this.$message.success("该订单处于异常状态，不允许更换！")
                }

                //显示弹框
                this.dialog = true;
                //获取订单id
                this.id = row.works_id

            },
            selectStaff() {
                staffList(localStorage.getItem("FuWuId")).then(res => {
                    console.log(res)
                    if (res.data.code == 0) {
                        this.options = res.data.data
                    }

                })
            },
            //点击返回列表页
            back() {
                this.status = !this.status
            },


            //------

            receive1: function (row) {
                //  alert(1);
                if (this.orderid2.length == 0) {
                    this.$message.error("未勾选订单")
                } else {
                    //显示弹框
                    this.dialog1 = true;
                }

            },

            handleSelectionChange(val) {
                this.multipleSelection = val;
                console.log(val)
                this.orderid2 = [];
                for (let i of this.multipleSelection) {
                    this.orderid2.push(i.id)
                }
                console.log(this.orderid2)
            },

            orderTodo: function () {
                //  alert(1);
                todoOrder(localStorage.getItem("FuWuId"), this.currentPage2, this.pagesize2).then(res => {
                    this.tableData2 = res.data.data
                    this.total2 = res.data.total
                    console.log(this.tableData2)
                })
            },

            handleSelectionChange2(val) {
                this.multipleSelection2 = val;
                this.orderid2 = [];
                console.log(this.multipleSelection2)
                for (let i of this.multipleSelection2) {
                    this.orderid2.push(i.id)
                }
                console.log(this.orderid2)
            },

            distribute() {
                // alert(1);
                this.dialog1 = false
                this.orderid2 = this.orderid2.join(",")
                orderDistribute(this.orderid2, this.form1.region).then(res => {
                    console.log(res)
                    if (res.data.code == 0) {
                        this.$message('分配成功');
                        this.orderTodo()
                    }
                })
            },


        },
        mounted() {
            this.balanced(), /*初始化数据都叫这个名字*/
                this.onSubmit(),
                this.selectStaff(),
                this.orderTodo(),
                this.jsamount()
        },


    }

</script>

<style>
    .orderPage {
        display: flex;
        justify-content: center;
    }

    .status_title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-weight: 500;
        color: #141414;
        border-bottom: 1px solid #e6e6e6;
    }

    .status_form {
        padding-top: 10px;
    }

    .to {
        font-size: 14px;
        color: #333;
        margin: 0 5px;
    }

    .articleTitle {
        height: 80px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141414;
        line-height: 80px;
        text-align: center;
    }

    .xs {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        text-align: center;
    }

    .bt {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }

    .xs .author {
        margin-right: 10px;
    }

    .file {
        width: 50%;
        height: 40px;
        background: #f7f7f7;
        border-radius: 4px;
        border: 1px solid #cccccc;
        padding: 10px;
        box-sizing: border-box;
        cursor: pointer;
    }

    .file span {
        font-size: 14px;
    }

    .articletext {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin: 10px;
    }

    .btn {
        width: 100px;
        background: red;
        border: red;
        margin-top: 20px;
    }

    .left_title {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .left_title span {
        margin-right: 10px;
    }

    /*.yue-list {*/
    /*  width: 100%;*/

    /*  display: flex;*/
    /*  justify-content: space-between;*/
    /*  margin-top: 20px;*/
    /*}*/


</style>